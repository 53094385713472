import logo from './saval.svg';
import './App.css';
import HTMLComment from 'react-html-comment';

function App() {
  return (
    <div className="App">
      <HTMLComment text="eaa16b9ced0b5c6ece7aae07cb47c671e8c8f03bfe807f941809477a847337afc5e4335527dee93b083dfcf553042f69583067951ec812149b3fbeb98cb63891f8aca02e28996a586f535eed5de9f4533b8b2910762f524459f6fae6fb3f8f7540db5f2c809c1c07167a95b33f6f3f85589af99182e2d2bf93f964de169dd4c01214bb6a46c7433592f5849cb8a7233f53966732e728c5fa6457eb3e2ba0a6567a81928d0deadac50a68b71f74a404a3df11c18d6262ebdc464d1716ba9dea21" />
      <header className="App-header">
        <img src={logo} alt="Saval Solutions" />
      </header>
    </div>
  );
}

export default App;
